@charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:    Stig
Version:    1.2.6
Last change:    29 June 2020
Primary use:    MultiPurpose Template  
-------------------------------------------------------------------*/

/* @import "font-awesome.min.css";
@import "et-line.css"; */
@import url(http://fonts.googleapis.com/css?family=Roboto:400,400italic,500,700,700italic,300);

/*------------------------------------------------------------------
[Table of contents]

1. Common styles
2. Page loader / .page-loader
3. Typography
4. Buttons
5. Forms fields
6. Home sections / .home-section
    6.1. Home content
    6.2. Scroll down icon
    6.3. Headings styles
7. Compact bar / .nav-bar-compact
8. Classic menu bar / .main-nav
9. Sections styles / .page-section, .small-section, .split-section
10. Services / .service-grid
11. Alternative services / .alt-service-grid
12. Team / .team-grid
13. Features
14. Benefits (Process)
15. Counters
16. Portfolio / #portfolio
    16.1. Works filter
    16.2. Works grid
    16.3. Works full view
17. Banner section
18. Testimonial
    18.1. Logotypes
19. Last posts (blog section)
20. Newsletter
21. Contact section
    21.1. Contact form
22. Bottom menu / .bot-menu
23. Google maps  /.google-map
24. Footer / .footer
25. Blog
    25.1. Pagination
    25.2. Comments
    25.3. Sidebar
26. Pricing
27. Shortcodes
    27.1. Owl Carousel
    27.2. Tabs
    27.3. Tabs minimal
    27.4. Alert messages
    27.5. Accordion
    27.6. Toggle
    27.7. Progress bars
    27.8. Font icons examples
    27.9. Intro page
28. Bootstrap multicolumn grid


/*------------------------------------------------------------------
# [Color codes]

# Black (active elements, links, text) #111
# Gray (text) #777
# Dark #111
# Dark Lighter #252525
# Gray BG #f4f4f4
# Gray BG Lighter #f8f8f8
# Mid grey #5f5f5f
# Borders #e9e9e9

# */


/*------------------------------------------------------------------
[Typography]

Body:               14px/1.6 "Open Sans", arial, sans-serif;
Headers:            Dosis, arial, sans-serif;
Serif:              "Times New Roman", Times, serif;
Section Title:      35px/1.3 "Open Sans", arial, sans-serif;
Section Heading:    24px/1.3 "Open Sans", arial, sans-serif;
Section Text:       15px/1.7 "Open Sans", arial, sans-serif;

-------------------------------------------------------------------*/


/* ==============================
   Common styles
   ============================== */

.align-center {
    text-align: center !important;
}

.align-left {
    text-align: left !important;
}

.align-right {
    text-align: right !important;
}

.left {
    float: left !important;
}

.right {
    float: right !important;
}

.relative {
    position: relative;
}

.hidden {
    display: none;
}

.inline-block {
    display: inline-block;
}

.white {
    color: #fff !important;
}

.black {
    color: #000 !important;
}

.color {
    color: #e91e63 !important;
}

.stick-fixed {
    position: fixed !important;
    top: 0;
    left: 0;
}

.round {
    -webkit-border-radius: 2px !important;
    -moz-border-radius: 2px !important;
    border-radius: 2px !important;
}

::-moz-selection {
    color: #fff;
    background: #000;
}

::-webkit-selection {
    color: #fff;
    background: #000;
}

::selection {
    color: #fff;
    background: #000;
}

.animate,
.animate * {
    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.no-animate,
.no-animate * {
    -webkit-transition: 0 none !important;
    -moz-transition: 0 none !important;
    -ms-transition: 0 none !important;
    -o-transition: 0 none !important;
    transition: 0 none !important;
}

.shadow {
    -webkit-box-shadow: 0px 3px 50px rgba(0, 0, 0, .15);
    -moz-box-shadow: 0px 3px 50px rgba(0, 0, 0, .15);
    box-shadow: 0px 3px 50px rgba(0, 0, 0, .15);
}

.white-shadow {
    -webkit-box-shadow: 0px 0px 200px rgba(255, 255, 255, .2);
    -moz-box-shadow: 0px 0px 200px rgba(255, 255, 255, .2);
    box-shadow: 0px 0px 200px rgba(255, 255, 255, .2);
}

.clearlist,
.clearlist li {
    list-style: none;
    padding: 0;
    margin: 0;
    background: none;
}

.full-wrapper {
    margin: 0 2%;
}

html {
    overflow-y: scroll;
    -ms-overflow-style: scrollbar;
}

html,
body {
    height: 100%;
    -webkit-font-smoothing: antialiased;
}

.page {
    overflow: hidden;
}

.mask-delay {
    -webkit-animation-delay: 0.75s;
    -moz-animation-delay: 0.75s;
    animation-delay: 0.75s;
}

.mask-speed {
    -webkit-animation-duration: 0.25s !important;
    -moz-animation-duration: 0.25s !important;
    animation-duration: 0.25s !important;
}

iframe {
    border: none;
}

a,
b,
div,
ul,
li {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    -moz-outline-: none;
}

a:focus:not(.focus-visible),
a:active,
section:focus,
div:active,
div:focus {
    -moz-outline: none;
    outline: none;
}

a:focus.focus-visible,
button:focus.focus-visible,
div:focus.focus-visible {
    -moz-outline: 2px dotted #36a367 !important;
    outline: 2px dotted #36a367 !important;
    outline-offset: 0 !important;
}

img:not([draggable]),
embed,
object,
video {
    max-width: 100%;
    height: auto;
}


/* ==============================
   Page loader
   ============================== */


.page-loader {
    display: block;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #fefefe;
    z-index: 100000;
}

.loader {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    ;
    margin: -20px 0 0 -20px;
    background-color: #e91e63;
    text-indent: -12345px;

    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;

    -webkit-animation: scaleout 1.0s infinite ease-in-out;
    animation: scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes scaleout {
    0% {
        -webkit-transform: scale(0.0)
    }

    100% {
        -webkit-transform: scale(1.0);
        opacity: 0;
    }
}

@keyframes scaleout {
    0% {
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
    }

    100% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
        opacity: 0;
    }
}



/* ==============================
   Typography
   ============================== */

body {
    color: #111;
    font-size: 16px;
    font-family: Roboto, arial, sans-serif;
    line-height: 1.7;
    -webkit-font-smoothing: antialiased;
}

a {
    color: #e91e63;
    text-decoration: none;
}

a:hover {
    color: #e91e63;
    text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 1.3em;
    line-height: 1.4;
    font-weight: 400;

}

h1 {
    font-size: 38px;
}

h2 {
    font-size: 34px;
}

h3 {
    font-size: 30px;
}

h4 {
    font-size: 26px;
}

h5 {
    font-size: 22px;
}

h6 {
    font-size: 18px;
    font-weight: 700;
}

p {
    margin: 0 0 2em 0;
}

ul,
ol {
    margin: 0 0 1.5em 0;
}

blockquote {
    margin: 3em 0 3em 0;
    padding: 0;
    border: none;
    background: none;
    font-style: normal;
    line-height: 1.7;
    color: #777;
}

blockquote p {
    margin-bottom: 1em;
    font-size: 24px;
    font-weight: 300;
    font-style: normal;
    line-height: 1.6;
}

blockquote footer {
    font-size: 11px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 2px;
}

dl dt {
    margin-bottom: 5px;
}

dl dd {
    margin-bottom: 15px;
}

pre {
    background-color: #f9f9f9;
    border-color: #ddd;

}

hr {
    background-color: rgba(255, 255, 255, .08);
}

hr.white {
    border-color: rgba(255, 255, 255, .35);
    background: rgba(255, 255, 255, .35);
}

hr.black {
    border-color: rgba(0, 0, 0, .85);
    background: rgba(0, 0, 0, .85);
}

.uppercase {
    text-transform: uppercase;
    letter-spacing: 1px;
}

.serif {
    font-family: "Times New Roman", Times, serif;
    font-style: italic;
    font-weight: normal;
}

.normal {
    font-weight: normal;
}

.strong {
    font-weight: 700 !important;
}

.lead {
    margin: 1em 0 2em 0;
    font-size: 140%;
    line-height: 1.6;
}

.text {
    font-size: 16px;
    font-weight: 300;
    color: #5f5f5f;
    line-height: 1.8;
}

.text h1,
.text h2,
.text h3,
.text h4,
.text h5,
.text h6 {
    color: #111;
}


.big-icon {
    display: block;
    width: 90px;
    height: 90px;
    margin: 0 auto 20px;
    border: 3px solid rgba(255, 255, 255, .93);
    text-align: center;
    line-height: 88px;
    font-size: 32px;
    color: rgba(255, 255, 255, .93);

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;

    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.big-icon .fa-play {
    position: relative;
    left: 4px;
}

.big-icon.big-icon-rs {
    border: none;
    width: auto;
    height: auto;
    font-size: 96px;
}

.big-icon-link,
.big-icon-link:focus {
    display: inline-block;
    text-decoration: none;
}

.big-icon-link:hover {
    border-color: transparent !important;
}

.big-icon-link:hover .big-icon {
    border-color: #fff;
    color: #fff;

    -webkit-transition: 0 none !important;
    -moz-transition: 0 none !important;
    -ms-transition: 0 none !important;
    -o-transition: 0 none !important;
    transition: 0 none !important;
}

.big-icon-link:active .big-icon {
    -webkit-transform: scale(0.99);
    -moz-transform: scale(0.99);
    -o-transform: scale(0.99);
    -ms-transform: scale(0.99);
    transform: scale(0.99);
}

.wide-heading {
    display: block;
    margin-bottom: 0;
    font-size: 48px;
    font-weight: 500;
    line-height: 1.3;
    text-align: center;
    color: rgba(255, 255, 255, .93);
    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.big-icon-link:hover,
.big-icon-link:hover .wide-heading {
    color: #fff;
    text-decoration: none;
}

.sub-heading {
    font-size: 20px;
    font-weight: 300;
    text-align: center;
    line-height: 1.3;
    opacity: .9;
}

.big-icon.black {
    color: #000;
    border-color: rgba(0, 0, 0, .95);
}

.big-icon-link:hover .big-icon.black {
    color: #000;
    border-color: #000;
}

.wide-heading.black {
    color: rgba(0, 0, 0, .9);
}

.big-icon-link.black:hover,
.big-icon-link:hover .wide-heading.black {
    color: #000;
    text-decoration: none;
}

.sub-heading.black {
    color: rgba(0, 0, 0, .85);
}



/* ==============================
   Buttons
   ============================== */

.btn-mod,
a.btn-mod {
    padding: 5px 13px;
    color: #fff;
    background: rgba(34, 34, 34, .9);
    border: 2px solid transparent;
    font-size: 11px;
    font-weight: 400;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 1px;

    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    -webkit-transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.btn-mod:hover,
.btn-mod:focus,
a.btn-mod:hover,
a.btn-mod:focus {
    background: rgba(0, 0, 0, .7);
    text-decoration: none;
    outline: none;
    color: #fff;
}

.btn-mod:active {
    cursor: pointer !important;
}

.btn-mod.btn-small {
    padding: 7px 17px 6px;
    font-size: 11px;
    letter-spacing: 1px;
}

.btn-mod.btn-medium {
    padding: 9px 37px 8px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 2px;
}

.btn-mod.btn-large {
    padding: 13px 45px 12px;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 2px;
}

.btn-mod.btn-glass {
    color: rgba(255, 255, 255, .75);
    background: rgba(0, 0, 0, .40);
}

.btn-mod.btn-glass:hover,
.btn-mod.btn-glass:focus {
    color: rgba(255, 255, 255, 1);
    background: rgba(0, 0, 0, 1);
}

.btn-mod.btn-border {
    color: #151515;
    border: 2px solid #151515;
    background: transparent;
}

.btn-mod.btn-border:hover,
.btn-mod.btn-border:focus {
    color: #fff;
    border-color: transparent;
    background: #000;
}

.btn-mod.btn-border-c {
    color: #e91e63;
    border: 2px solid #e91e63;
    background: transparent;
}

.btn-mod.btn-border-c:hover,
.btn-mod.btn-border-c:focus {
    color: #fff;
    border-color: transparent;
    background: #e91e63;
}

.btn-mod.btn-border-w {
    color: #fff;
    border: 2px solid rgba(255, 255, 255, .75);
    background: transparent;
}

.btn-mod.btn-border-w:hover,
.btn-mod.btn-border-w:focus {
    color: #111;
    border-color: transparent;
    background: #fff;
}

.btn-mod.btn-w {
    color: #111;
    background: rgba(255, 255, 255, .9);
}

.btn-mod.btn-w:hover,
.btn-mod.btn-w:focus {
    color: #111;
    background: #fff;
}

.btn-mod.btn-w-color {
    color: #e91e63;
    background: #fff;
}

.btn-mod.btn-w-color:hover,
.btn-mod.btn-w-color:focus {
    color: #151515;
    background: #fff;
}

.btn-mod.btn-gray {
    color: #777;
    background: #e5e5e5;
}

.btn-mod.btn-gray:hover,
.btn-mod.btn-gray:focus {
    color: #444;
    background: #d5d5d5;
}

.btn-mod.btn-color {
    color: #fff;
    background: #e91e63;
}

.btn-mod.btn-color:hover,
.btn-mod.btn-color:focus {
    color: #fff;
    background: #e91e63;
    opacity: .85;
}

.btn-mod.btn-circle {
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
}

.btn-mod.btn-round {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
}

.btn-icon {
    position: relative;
    border: none;
    overflow: hidden;
}

.btn-icon.btn-small {
    overflow: hidden;
}

.btn-icon>span {
    width: 100%;
    height: 50px;
    line-height: 50px;
    margin-top: -25px;
    position: absolute;
    top: 50%;
    left: 0;
    color: #777;
    font-size: 48px;
    opacity: .2;

    -webkit-transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.btn-icon:hover>span {
    opacity: 0;

    -webkit-transform: scale(2);
    -moz-transform: scale(2);
    -o-transform: scale(2);
    -ms-transform: scale(2);
    transform: scale(2);
}

.btn-icon>span.white {
    color: #fff;
}

.btn-icon>span.black {
    color: #000;
}

.btn-full {
    width: 100%;
}

.inner-nav .btn {
    margin-top: -3px;
}

mobile-on .inner-nav .btn {
    margin-top: 0;
}


/* ==============================
   Forms fields
   ============================== */

.form input[type="text"],
.form input[type="email"],
.form input[type="number"],
.form input[type="url"],
.form input[type="search"],
.form input[type="tel"],
.form input[type="password"],
.form input[type="date"],
.form input[type="color"],
.form select {
    display: inline-block;
    height: 27px;
    vertical-align: middle;
    font-size: 11px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #999;
    border: 1px solid rgba(0, 0, 0, .1);
    padding-left: 7px;
    padding-right: 7px;

    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    -webkit-transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.form select {
    font-size: 12px;
}

.form select[multiple] {
    height: auto;
}

.form input[type="text"]:hover,
.form input[type="email"]:hover,
.form input[type="number"]:hover,
.form input[type="url"]:hover,
.form input[type="search"]:hover,
.form input[type="tel"]:hover,
.form input[type="password"]:hover,
.form input[type="date"]:hover,
.form input[type="color"]:hover,
.form select:hover {
    border-color: rgba(0, 0, 0, .2);
}

.form input[type="text"]:focus,
.form input[type="email"]:focus,
.form input[type="number"]:focus,
.form input[type="url"]:focus,
.form input[type="search"]:focus,
.form input[type="tel"]:focus,
.form input[type="password"]:focus,
.form input[type="date"]:focus,
.form input[type="color"]:focus,
.form select:focus {
    color: #111;
    border-color: rgba(0, 0, 0, .7);

    -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, .3);
    -moz-box-shadow: 0 0 3px rgba(0, 0, 0, .3);
    box-shadow: 0 0 3px rgba(0, 0, 0, .3);
    outline: none;
}

.form input[type="text"].input-lg,
.form input[type="email"].input-lg,
.form input[type="number"].input-lg,
.form input[type="url"].input-lg,
.form input[type="search"].input-lg,
.form input[type="tel"].input-lg,
.form input[type="password"].input-lg,
.form input[type="date"].input-lg,
.form input[type="color"].input-lg,
.form select.input-lg {
    height: 50px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 13px;
}

.form input[type="text"].input-md,
.form input[type="email"].input-md,
.form input[type="number"].input-md,
.form input[type="url"].input-md,
.form input[type="search"].input-md,
.form input[type="tel"].input-md,
.form input[type="password"].input-md,
.form input[type="date"].input-md,
.form input[type="color"].input-md,
.form select.input-md {
    height: 37px;
    padding-left: 13px;
    padding-right: 13px;
    font-size: 12px;
}

.form input[type="text"].input-sm,
.form input[type="email"].input-sm,
.form input[type="number"].input-sm,
.form input[type="url"].input-sm,
.form input[type="search"].input-sm,
.form input[type="tel"].input-sm,
.form input[type="password"].input-sm,
.form input[type="date"].input-sm,
.form input[type="color"].input-sm,
.form select.input-sm {
    height: 31px;
    padding-left: 10px;
    padding-right: 10px;
}

.form textarea {
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase !important;
    letter-spacing: 1px;
    color: #777;
    border-color: rgba(0, 0, 0, .1);
    outline: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.form textarea:hover {
    border-color: rgba(0, 0, 0, .2);
}

.form textarea:focus {
    color: #111;
    border-color: rgba(0, 0, 0, .7);
    -webkit-box-shadow: 0 0 3px rgba(21, 21, 21, .3);
    -moz-box-shadow: 0 0 3px rgba(21, 21, 21, .3);
    box-shadow: 0 0 3px rgba(21, 21, 21, .3);
}

.form textarea.input-lg {
    padding-top: 17px;
    padding-bottom: 17px;
}

.form .form-group {
    margin-bottom: 10px;
}

.form input.error {
    border-color: #e41919;
}

.form div.error {
    margin-top: 20px;
    padding: 13px 20px;
    text-align: center;
    background: rgba(228, 25, 25, .75);
    color: #fff;
}

.form div.error a,
.form div.error a:hover {
    color: #fff;
    text-decoration: underline;
}

.form div.success {
    margin-top: 20px;
    padding: 13px 20px;
    text-align: center;
    background: rgba(50, 178, 95, .75);
    color: #fff;
}

.form-tip {
    font-size: 12px;
    color: #aaa;
}

.form-tip .fa {
    font-size: 12px;
}


/* ==============================
   Home sections
   ============================== */

/*
 * Header common styles
 */

.home-section {
    width: 100%;
    display: block;
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.home-content {
    display: table;
    width: 100%;
    height: 100%;
    text-align: center;
}


/* Home content */

.home-content {
    position: relative;
}

.home-text {
    display: table-cell;
    height: 100%;
    vertical-align: middle;
}

/* Scroll down icon */

@-webkit-keyframes scroll-down-anim {
    0% {
        bottom: 12px;
    }

    50% {
        bottom: 20px;
    }

    100% {
        bottom: 12px;
    }
}

@-moz-keyframes scroll-down-anim {
    0% {
        bottom: 12px;
    }

    50% {
        bottom: 20px;
    }

    100% {
        bottom: 12px;
    }
}

@-o-keyframes scroll-down-anim {
    0% {
        bottom: 12px;
    }

    50% {
        bottom: 20px;
    }

    100% {
        bottom: 12px;
    }
}

@keyframes scroll-down-anim {
    0% {
        bottom: 12px;
    }

    50% {
        bottom: 20px;
    }

    100% {
        bottom: 12px;
    }
}

.scroll-down {
    width: 40px;
    height: 40px;
    margin-left: -20px;
    position: absolute;
    bottom: 20px;
    left: 50%;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 1px 0 rgba(0, 0, 0, .25);
    color: rgba(255, 255, 255, .9);

    -webkit-transition: all 500ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
    -moz-transition: all 500ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
    -o-transition: all 500ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
    transition: all 500ms cubic-bezier(0.250, 0.460, 0.450, 0.940);

}

.scroll-down:hover {
    color: #fff;
}

.scroll-down.black {
    text-shadow: none;
    color: rgba(0, 0, 0, .9);
}

.scroll-down-icon {
    display: block;
    width: 20px;
    height: 20px;
    margin-left: -10px;
    position: absolute;
    left: 50%;
    bottom: 4px;
    font-size: 32px;

    -webkit-animation: scroll-down-anim 1s infinite;
    -moz-animation: scroll-down-anim 1s infinite;
    -o-animation: scroll-down-anim 1s infinite;
    animation: scroll-down-anim 1s infinite;


    z-index: 2;
}





/* 
 * Headings styles
 */


.hs-wrap {
    max-width: 570px;
    margin: 0 auto;
    padding: 47px 30px 53px;
    position: relative;
    text-align: center;

    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    border: 1px solid rgba(255, 255, 255, .5);
}

.hs-wrap-1 {
    max-width: 350px;
    margin: 0 auto;
    position: relative;
    text-align: center;

    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.hs-line-1 {
    margin-top: 0;
    position: relative;
    text-transform: uppercase;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 1px;
}

.hs-line-2 {
    margin-top: 0;
    position: relative;
    font-size: 64px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.37;
}

.hs-line-3 {
    margin-top: 0;
    position: relative;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.hs-line-4 {
    margin-top: 0;
    position: relative;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, .85);
}

.hs-line-5 {
    margin-top: 0;
    position: relative;
    font-size: 64px;
    font-weight: 300;
    line-height: 1.2;
}

.hs-line-6 {
    margin-top: 0;
    position: relative;
    font-size: 20px;
    font-weight: 300;
    line-height: 1.2;
}

.hs-line-7 {
    margin-top: 0;
    position: relative;
    font-size: 64px;
    font-weight: 800;
    text-transform: uppercase;
    line-height: 1.2;
}

.hs-line-8 {
    margin-top: 0;
    position: relative;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.2;
}

.hs-line-9 {
    margin-top: 0;
    position: relative;
    font-size: 96px;
    font-weight: 800;
    text-transform: uppercase;
    line-height: 1.2;
}

.hs-line-10 {
    margin-top: 0;
    display: inline-block;
    position: relative;
    padding: 1px 10px;
    background: rgba(0, 0, 0, .9);
    font-size: 30px;
    font-weight: 300;
    text-transform: uppercase;
}

.hs-line-10>span {
    margin: 0 10px;
}

.hs-line-10.highlight {
    background: #e91e63;
    opacity: .97;
}

.hs-line-10.transparent {
    background: none;
    padding: 0;
}

.hs-line-11 {
    margin-top: 0;
    position: relative;
    font-size: 30px;
    font-weight: 300;
    line-height: 1.8;
}

.hs-line-11 strong {
    font-weight: 500;
}

.hs-line-12 {
    margin-top: 0;
    position: relative;
    font-size: 80px;
    font-weight: 400;
    line-height: 1.2;
    text-transform: uppercase;
}

.hs-line-13 {
    margin-top: 0;
    position: relative;
    font-size: 96px;
    font-weight: 500;
    line-height: 1;
}

.hs-line-14 {
    margin-top: 0;
    position: relative;
    font-size: 48px;
    font-weight: 400;
    line-height: 1.4;
}

.no-transp {
    opacity: 1 !important;
}

.fullwidth-galley-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 25;
}


/* ==============================
   Classic menu bar
   ============================== */


/* Logo (in navigation panel)*/

.nav-logo-wrap {
    float: left;
    margin-right: 20px;
}

.nav-logo-wrap .logo {
    display: block;
    vertical-align: middle;
    max-width: 120px;
    height: 75px;

    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.nav-logo-wrap .logo img {
    max-height: 100%;
    position: relative;
    top: 50%;
    padding: 5px 0;

    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.nav-logo-wrap .logo:before,
.nav-logo-wrap .logo:after {
    display: none;
}

.logo,
a.logo:hover {
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-decoration: none;
    color: rgba(0, 0, 0, .9);
}

/*
 * Desktop nav
 */

.main-nav {
    width: 100%;
    height: 75px !important;
    position: relative;
    top: 0;
    left: 0;
    text-align: left;
    background: rgba(255, 255, 255, .95);

    -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, .05);
    -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, .05);
    box-shadow: 0 1px 0 rgba(0, 0, 0, .05);

    z-index: 201;

    -webkit-transition: height 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: height 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: height 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: height 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: height 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.small-height {
    height: 55px !important;
}

.small-height .inner-nav ul li a {
    height: 55px !important;
    line-height: 55px !important;
}

.inner-nav {
    display: inline-block;
    position: relative;
    float: right;
}

.inner-nav ul {
    float: right;
    margin: auto;
    font-size: 13px;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    line-height: 1.2;
}

.inner-nav ul li {
    float: left;
    margin-left: 30px;
    position: relative;
}

.inner-nav ul li a {
    color: #777;
    letter-spacing: 1px;
    display: inline-block;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-decoration: none;

    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.inner-nav ul>li:first-child>a {}

.inner-nav ul li a:hover,
.inner-nav ul li a.active {
    color: #000;
    text-decoration: none;
}



/* Menu sub */

.mn-sub {
    display: none;
    width: 200px;
    position: absolute;
    top: 100%;
    left: 0;
    padding: 0;
    background: rgba(30, 30, 30, .97);
}

.mn-sub li {
    display: block;
    width: 100%;
    margin: 0 !important;
}

.inner-nav ul li .mn-sub li a {
    display: block;
    width: 100%;
    height: auto !important;
    line-height: 1.3 !important;
    position: relative;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 11px 15px;
    font-size: 13px;
    text-align: left;
    text-transform: none;
    border-left: none;
    border-right: none;
    letter-spacing: 0;
    color: #b0b0b0 !important;
    cursor: pointer;
}

.mobile-on .mn-has-multi>li:last-child>a {
    border-bottom: 1px solid rgba(255, 255, 255, .065) !important;
}

.mn-sub li a:hover,
.inner-nav ul li .mn-sub li a:hover,
.mn-sub li a.active {
    background: rgba(255, 255, 255, .09);
    color: #f5f5f5 !important;
}

.mn-sub li ul {
    left: 100%;
    right: auto;
    top: 0;
    border-left: 1px solid rgba(255, 255, 255, .07);
}

.inner-nav>ul>li:last-child .mn-sub,
.inner-nav li .mn-sub.to-left {
    left: auto;
    right: 0;
}

.inner-nav li:last-child .mn-sub li ul,
.inner-nav li .mn-sub li ul.to-left {
    left: auto;
    right: 100%;
    top: 0;
    border-left: none;
    border-right: 1px solid rgba(255, 255, 255, .07);
}

.mobile-on .inner-nav li:last-child .mn-sub li ul {
    left: auto;
    right: auto;
    top: 0;
}

.mn-wrap {
    padding: 8px;
}

/* Menu sub multi */

.mn-has-multi {
    width: auto !important;
    padding: 15px;
    text-align: left;
    white-space: nowrap;
}

.mn-sub-multi {
    width: 190px !important;
    overflow: hidden;
    white-space: normal;
    float: none !important;
    display: inline-block !important;
    vertical-align: top;
}

.mn-sub-multi>ul {
    width: auto;
    margin: 0;
    padding: 0;
    float: none;
}

.mn-group-title {
    font-weight: 700;
    text-transform: uppercase;
    color: #fff !important;
}

.mn-group-title:hover {
    background: none !important;
}

.mobile-on .mn-sub-multi>ul {
    left: auto;
    right: auto;
    border: none;
}

.mobile-on .mn-sub-multi {
    display: block;
    width: 100% !important;
}


.mobile-on .mn-sub-multi>ul>li:last-child>a {
    border: none !important;
}

.mobile-on .mn-sub>li:last-child>a {
    border: none !important;
}

.mobile-on .mn-sub-multi:last-child>ul {
    border-bottom: none !important;
}

/*
 * Mobile nav
 */

.mobile-on .desktop-nav {
    display: none;
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    padding: 0 15px;

    z-index: 100;
}

.mobile-on .full-wrapper .desktop-nav {
    padding: 0;
}


.mobile-on .desktop-nav ul {
    width: 100%;
    float: none;
    background: rgba(18, 18, 18, .97);
    position: relative;
    overflow-x: hidden;
}

.mobile-on .desktop-nav>ul {
    overflow-y: auto;
}

.mobile-on .desktop-nav ul li {
    display: block;
    float: none !important;
    overflow: hidden;
}

.mobile-on .desktop-nav ul li ul {
    padding: 0;
    border-bottom: 1px solid rgba(255, 255, 255, .1);
}

.mobile-on .desktop-nav ul li a {
    display: block;
    width: 100%;
    height: 38px !important;
    line-height: 38px !important;
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 10px;
    text-align: left;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid rgba(255, 255, 255, .065);
    color: #999;
    letter-spacing: 1px;
    cursor: pointer;
}

.mobile-on .desktop-nav ul li a:hover,
.mobile-on .desktop-nav ul li a.active {
    background: rgba(255, 255, 255, .03);
    color: #ddd;
}

/* Menu sub */

.mobile-on .mn-has-sub>.fa {
    display: block;
    width: 18px;
    height: 18px;
    font-size: 14px;
    line-height: 16px;
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -8px;
    text-align: center;
    border: 1px solid rgba(255, 255, 255, .1);

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.mobile-on .js-opened>.mn-has-sub>.fa {
    border-color: rgba(255, 255, 255, .4);
    line-height: 14px;
}

.mobile-on .inner-nav li {
    margin: 0 !important;
}

.mobile-on .mn-sub {
    display: none;
    opacity: 0;
    position: relative;
    left: 0 !important;
    right: 0;
    top: 0;
    bottom: 0;
    border-left: none !important;
    border-right: none !important;
    border-top: none;
    z-index: 10;
}

.mobile-on .mn-sub li {
    padding-left: 10px;
}


.inner-nav li.js-opened .mn-sub {
    opacity: 1;
}


.mobile-nav {
    display: none;
    float: right;
    vertical-align: middle;
    font-size: 11px;
    font-weight: 400;
    text-transform: uppercase;
    border-left: 1px solid rgba(0, 0, 0, .05);
    border-right: 1px solid rgba(0, 0, 0, .05);
    cursor: pointer;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;

    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);

    -webkit-transition-property: background, color;
    -moz-transition-property: background, color;
    -o-transition-property: background, color;
    transition-property: background, color;
}

.mobile-nav:hover,
.mobile-nav.active {
    background: #fff;
    color: #777;
}

.mobile-nav:active {
    -webkit-box-shadow: 0 0 35px rgba(0, 0, 0, .05) inset;
    -moz-box-shadow: 0 0 35px rgba(0, 0, 0, .05) inset;
    box-shadow: 0 0 35px rgba(0, 0, 0, .05) inset;
}

.no-js .mobile-nav {
    width: 55px;
    height: 55px;
}

.mobile-on .mobile-nav {
    display: table;
}

.mobile-nav .fa {
    height: 100%;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    font-size: 21px;
}

/* Menu sub multi */

.mobile-on .mn-has-multi {
    width: auto;
    padding: 0;
    text-align: left;
    white-space: normal;

}

.mobile-on .mn-sub {
    width: 100%;
    float: none;
    position: relative;
    overflow-x: hidden;
}

/*
 * Dark panel style
 */

.main-nav.dark {
    background-color: rgba(17, 17, 17, .95);

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.main-nav.dark .inner-nav ul>li>a {
    color: rgba(255, 255, 255, .7);
}

.main-nav.dark .inner-nav ul>li>a:hover,
.main-nav.dark .inner-nav ul>li>a.active {
    color: #fff;
}

.main-nav.dark .mobile-nav {
    background-color: transparent;
    border-color: transparent;
    color: rgba(255, 255, 255, .9);
}

.main-nav.dark .mobile-nav:hover,
.main-nav.dark .mobile-nav.active {
    color: #fff;
}

.main-nav.mobile-on.dark .inner-nav {
    border-top: 1px solid rgba(100, 100, 100, .4);
}

.main-nav.dark .logo,
.main-nav.dark a.logo:hover {
    font-size: 18px;
    font-weight: 800;
    text-transform: uppercase;
    text-decoration: none;
    color: rgba(255, 255, 255, .9);
}

.main-nav.dark .mn-sub {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}


/*
 * Transparent panel style
 */

.main-nav.transparent {
    background: transparent !important;
}

.main-nav.js-transparent {
    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}


/* ==============================
   Sections styles
   ============================== */

/*
 * Common
 */

.page-section,
.small-section {
    width: 100%;
    display: block;
    position: relative;
    overflow: hidden;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center center;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    padding: 140px 0;
}

.small-section {
    padding: 70px 0;
}

.small-title {
    padding: 0;
    margin: 0 0 30px 0;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 1px;
}

.section-icon {
    margin-bottom: 7px;
    text-align: center;
    font-size: 48px;
}

.small-title .serif {
    text-transform: none;
    font-weight: 400;
}

.small-title.white {
    color: #fff;
}

.bg-scroll {
    background-attachment: scroll !important;
}

.bg-pattern {
    background-repeat: repeat;
    background-size: auto;
}

.bg-pattern-over:before,
.bg-pattern-over .YTPOverlay:after {
    content: " ";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: repeat;
    background-size: auto;
    /* background-image: url(../images/pattern-bg-2.png); */
}

.bg-gray {
    background-color: #f4f4f4;
}

.bg-gray-lighter {
    background-color: #f8f8f8;
}

.bg-dark,
.bg-dark-lighter,
.bg-dark-alfa-30,
.bg-dark-alfa-50,
.bg-dark-alfa-70,
.bg-dark-alfa-90,
.bg-dark-alfa,
.bg-color,
.bg-color-lighter,
.bg-color-alfa-30,
.bg-color-alfa-50,
.bg-color-alfa-70,
.bg-color-alfa-90,
.bg-color-alfa {
    color: #fff;
}

.bg-dark a,
.bg-dark-lighter a,
.bg-dark-alfa-30 a,
.bg-dark-alfa-50 a,
.bg-dark-alfa-70 a,
.bg-dark-alfa-90 a,
.bg-dark-alfa a,
.bg-color a,
.bg-colorlighter a,
.bg-color-alfa-30 a,
.bg-color-alfa-50 a,
.bg-color-alfa-70 a,
.bg-color-alfa-90 a,
.bg-color-alfa a,

.bg-dark .mod-breadcrumbs a,
.bg-dark-lighter .mod-breadcrumbs a,
.bg-dark-alfa-30 .mod-breadcrumbs a,
.bg-dark-alfa-50 .mod-breadcrumbs a,
.bg-dark-alfa-70 .mod-breadcrumbs a,
.bg-dark-alfa-90 .mod-breadcrumbs a,
.bg-dark-alfa .mod-breadcrumbs a,
.bg-color .mod-breadcrumbs a,
.bg-colorlighter .mod-breadcrumbs a,
.bg-color-alfa-30 .mod-breadcrumbs a,
.bg-color-alfa-50 .mod-breadcrumbs a,
.bg-color-alfa-70 .mod-breadcrumbs a,
.bg-color-alfa-90 .mod-breadcrumbs a,
.bg-color-alfa .mod-breadcrumbs a {
    color: rgba(255, 255, 255, .8);
    padding-bottom: 3px;

    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.bg-dark a:hover,
.bg-dark-lighter a:hover,
.bg-dark-alfa-30 a:hover,
.bg-dark-alfa-50 a:hover,
.bg-dark-alfa-70 a:hover,
.bg-dark-alfa-90 a:hover,
.bg-dark-alfa a:hover,
.bg-color a:hover,
.bg-color-lighter a:hover,
.bg-color-alfa-30 a:hover,
.bg-color-alfa-50 a:hover,
.bg-color-alfa-70 a:hover,
.bg-color-alfa-90 a:hover,
.bg-color-alfa a:hover {
    color: rgba(255, 255, 255, 1);
    text-decoration: none;
}

.bg-color .text,
.bg-color-lighter .text,
.bg-color-alfa-30 .text,
.bg-color-alfa-50 .text,
.bg-color-alfa-70 .text,
.bg-color-alfa-90 .text,
.bg-color-alfa .text,
.bg-color .section-text,
.bg-color-lighter .section-text,
.bg-color-alfa-30 .section-text,
.bg-color-alfa-50 .section-text,
.bg-color-alfa-70 .section-text,
.bg-color-alfa-90 .section-text,
.bg-color-alfa .section-text {
    color: rgba(255, 255, 255, .75);
}

.bg-color .white,
.bg-color-lighter .whitet,
.bg-color-alfa-30 .white,
.bg-color-alfa-50 .white,
.bg-color-alfa-70 .white,
.bg-color-alfa-90 .white,
.bg-color-alfa .white {
    color: #fff;
}


.bg-dark {
    background-color: #111;
}

.bg-dark-lighter {
    background-color: #252525;
}

.bg-dark-alfa:before,
.bg-dark-alfa .YTPOverlay:before {
    content: " ";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(17, 17, 17, .97);
}

.bg-dark-alfa-30:before,
.bg-dark-alfa-30 .YTPOverlay:before {
    content: " ";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(34, 34, 34, .3);
}

.bg-dark-alfa-50:before,
.bg-dark-alfa-50 .YTPOverlay:before {
    content: " ";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(34, 34, 34, .5);
}

.bg-dark-alfa-70:before,
.bg-dark-alfa-70 .YTPOverlay:before {
    content: " ";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(17, 17, 17, .7);
}

.bg-dark-alfa-90:before,
.bg-dark-alfa-90 .YTPOverlay:before {
    content: " ";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(20, 20, 20, .9);
}

.bg-light-alfa:before {
    content: " ";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(252, 252, 252, .97);
}

.bg-light-alfa-30:before,
.bg-light-alfa-30 .YTPOverlay:before {
    content: " ";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(252, 252, 252, .30);
}

.bg-light-alfa-50:before,
.bg-light-alfa-50 .YTPOverlay:before {
    content: " ";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(252, 252, 252, .50);
}

.bg-light-alfa-70:before,
.bg-light-alfa-70 .YTPOverlay:before {
    content: " ";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(252, 252, 252, .7);
}

.bg-light-alfa-90:before,
.bg-light-alfa-90 .YTPOverlay:before {
    content: " ";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(252, 252, 252, .9);
}

.bg-color {
    background-color: #e91e63;
}

.bg-colort-alfa:before,
.bg-colort-alfa .YTPOverlay:before {
    content: " ";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #e91e63;
    opacity: .97;
}

.bg-color-alfa-30:before,
.bg-color-alfa-30 .YTPOverlay:before {
    content: " ";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #e91e63;
    opacity: .3;
}

.bg-color-alfa-50:before,
.bg-color-alfa-50 .YTPOverlay:before {
    content: " ";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #e91e63;
    opacity: .5;
}

.bg-color-alfa-70:before,
.bg-color-alfa-70 .YTPOverlay:before {
    content: " ";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #e91e63;
    opacity: .7;
}

.bg-color-alfa-90:before,
.bg-color-alfa-90 .YTPOverlay:before {
    content: " ";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #e91e63;
    opacity: .9;
}

.bg-orange {
    background-color: #f47631 !important;
}

.bg-yellow {
    background-color: #ffce02 !important;
}

.bg-cyan {
    background-color: #27c5c3 !important;
}

.bg-green {
    background-color: #58bf44 !important;
}

.bg-blue {
    background-color: #43a5de !important;
}

.bg-purple {
    background-color: #375acc !important;
}

.bg-red {
    background-color: #e13939 !important;
}

.section-title {
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 24px;
    font-weight: 300;
    line-height: 1.3;
}

.section-title.small {
    font-size: 20px;
}

.section-title.large {
    font-size: 27px;
}

.section-more {
    margin-top: 7px;
    color: #aaa;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;

    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.section-more:hover {
    text-decoration: none;
    color: #000;

}

.section-more .fa {
    font-size: 12px;
}

.section-heading {
    margin-top: 0;
    margin-bottom: 28px;
    font-size: 36px;
    font-weight: 300;
    line-height: 1.45;
}

.section-line {
    width: 80px;
    height: 2px;
    margin: 30px auto;
    background: #111;
}

.section-line.white {
    background-color: #fff;
}

.section-text {
    font-size: 18px;
    font-weight: 300;
    color: #777;
    line-height: 1.7778;
}

.section-text blockquote {
    margin-top: -5px;
}

.section-bot-image {
    margin: 140px 0 -140px;
    text-align: center;
}

/* Breadcrumbs */

.mod-breadcrumbs {
    margin-top: -5px;
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 1px;
    text-decoration: none;
    text-transform: uppercase;

    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.mod-breadcrumbs span {
    opacity: .65;
}

.mod-breadcrumbs a {
    text-decoration: none;
    color: #111;

}

.mod-breadcrumbs a:hover {
    text-decoration: underline;
}

.mod-breadcrumbs .fa {
    font-size: 12px;
}

.home-bot-image {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: center;
}

.fixed-height-large,
.fixed-height-medium,
.fixed-height-small {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.fixed-height-large {
    min-height: 800px;
}

.fixed-height-medium {
    min-height: 700px;
}

.fixed-height-small {
    min-height: 600px;
}


/*
 * Split section
 */

.split-section {
    width: 100%;
    display: block;
    position: relative;
    overflow: hidden;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.split-section-headings {
    width: 50%;
    position: relative;
    overflow: hidden;
    text-align: center;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.ssh-table {
    width: 100%;
    min-height: 500px;
    display: table;
}

.ssh-cell {
    display: table-cell;
    padding-left: 10px;
    padding-right: 10px;
    vertical-align: middle;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.split-section-content {
    width: 50%;
    position: relative;
    overflow: hidden;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.split-section-wrapper {
    padding: 0 80px;
}


/* ==============================
   Services
   ============================== */

.service-grid {
    margin-top: -30px;
}

.service-item {
    margin: 30px 0 0 0;
    position: relative;
    padding: 40px 20px;
    font-size: 15px;
    font-weight: 300;
    text-align: center;
    color: #777;
    background: #f5f5f5;
}

.service-icon {
    font-size: 64px;
    line-height: 1.05;
}

.service-title {
    font-size: 22px;
    font-weight: 400;
    line-height: 0.9;
    color: #111;
}

/* Hoer state */

.service-item {
    -webkit-transition: background-color 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: background-color 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: background-color 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: background-color 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: background-color 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.service-item:hover {
    background-color: #e91e63;
    color: #fff;
}

.service-item:hover .service-title,
.service-item:hover .service-icon,
.service-item:hover .service-icon.color {
    color: #fff !important;
}


/* ==============================
   Alternative services
   ============================== */

.alt-service-grid {
    margin-top: -60px;
}

.alt-services-image {
    text-align: center;
}

.alt-services-image>img {
    display: block;
    width: 100%;
}

.alt-service-item {
    margin: 60px 0 0 0;
    position: relative;
    padding-left: 55px;
    padding-top: 3px;
    font-size: 13px;
    font-weight: 300;
    color: #999;

    -webkit-transform: perspective(500px) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    -moz-transform: perspective(500px) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    -o-transform: perspective(500px) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    -ms-transform: perspective(500px) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    transform: perspective(500px) rotateX(0deg) rotateY(0deg) rotateZ(0deg);

    -webkit-transition: all 0.3s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: all 0.3s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: all 0.3s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.3s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.3s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.alt-service-icon {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    left: 0;
    line-height: 40px;
    text-align: center;
    font-size: 28px;
    color: #111;

    -webkit-transition: all 0.17s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: all 0.17s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: all 0.17s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.17s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.17s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.alt-services-title {
    margin: 0 0 10px 0;
    padding: 0;
    font-size: 15px;
    font-weight: 400;
    color: #111;
}



/* ==============================
   Team
   ============================== */

.team-grid {}

.team-item {}

.team-item-image {
    position: relative;
    overflow: hidden;
    border-bottom: 2px solid #e91e63;
}

.team-item-image.no-bord {
    border: none;
}

.team-item-image img {
    width: 100%;
}

.team-item-image:after {
    content: " ";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;

    z-index: 1;

    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.team-item-detail {
    opacity: 0;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    padding: 20px 23px;
    font-size: 14px;
    font-weight: 300;
    color: #777;
    text-align: center;
    z-index: 2;

    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.team-item-h {
    font-weight: 300;
}

.team-item-descr {
    color: #555;
    text-align: center;
    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.team-item-name {
    margin-top: 20px;
    font-size: 17px;
}

.team-item-role {
    margin-top: 1px;
    font-size: 14px;
    color: #999;
}

.team-social-links {
    font-size: 14px;
    cursor: pointer;
}

.team-social-links a {
    display: inline-block;
    padding: 3px 7px;
    color: rgba(0, 0, 0, .4);

    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}



/* Hover state */
.no-mobile .team-item:hover .team-item-image:after {
    background: rgba(255, 255, 255, .91);
}

.no-mobile .team-item:hover .team-item-detail {
    opacity: 1;
}

.no-mobile .team-item:hover .team-item-descr {
    color: #111;
}

.no-mobile .team-social-links a:hover {
    color: rgba(0, 0, 0, .75);
}

/* Mobile touch */
.team-item.js-active .team-item-image:after {
    background: rgba(255, 255, 255, .93);
}

.team-item.js-active .team-item-detail {
    opacity: 1;
}

.team-item.js-active .team-item-descr {
    color: #111;
}

.team-item.js-active .team-social-links a:hover {
    color: rgba(0, 0, 0, .75);
}


/* ==============================
   Features
   ============================== */

.features-item {
    padding: 15px;
    text-align: center;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.features-icon {
    font-size: 48px;
    line-height: 1.3;
    opacity: .9;
}

.features-title {
    margin-bottom: 3px;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
    opacity: .9;
}

.features-descr {
    font-size: 15px;
    font-weight: 300;
    opacity: .75;
}



/* ==============================
   Alt features
   ============================== */

.alt-features-grid {
    margin-top: -70px;
}

.alt-features-item {
    margin-top: 70px;
}

.alt-features-icon {
    padding-top: 10px;
    font-size: 48px;
    line-height: 0.9;
    color: #111;
}

.alt-features-icon img {
    width: 100%;
}

.alt-features-title {
    margin-bottom: 21px;
    font-size: 15px;
    font-weight: 400;
}

.alt-features-descr {
    color: #777;
    font-size: 15px;
    font-weight: 300;
    line-height: 1.86
}


/* ==============================
   Alt features 1
   ============================== */

.alt-features-grid-1 {
    margin-top: -70px;
}

.alt-features-item-1 {
    margin-top: 70px;
}

.alt-features-icon-1 {
    width: 64px;
    height: 64px;
    display: inline-block;
    font-size: 28px;
    line-height: 64px;
    color: #fff;
    background: #e91e63;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.alt-features-icon-1 img {
    width: 100%;
}

.alt-features-title-1 {
    margin-bottom: 5px;
    font-size: 20px;
    font-weight: 300;
}

.alt-features-descr-1 {
    color: #777;
    font-size: 19px;
    font-weight: 300;
}


/* ==============================
   Benefits
   ============================== */

.benefits-grid {
    margin: 0 0 -40px -20px;
    text-align: center;
    vertical-align: top;
}

.benefit-item {
    display: inline-block;
    width: 264px;
    margin: 0 0 40px 20px;
    padding: 15px;
    position: relative;
    overflow: hidden;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    vertical-align: top;
    cursor: default;
}

.benefit-number {
    width: 100%;
    position: absolute;
    top: 0;
    left: -50px;
    font-size: 120px;
    font-weight: 700;
    text-align: center;
    line-height: 1;
    color: rgba(0, 0, 0, .025);

    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.benefit-icon {
    position: relative;
    margin-bottom: 6px;
    font-size: 32px;
}

.benefit-title {
    position: relative;
    margin: 0 0 17px 0;
    padding: 0;
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.benefits-descr {
    position: relative;
    font-size: 13px;
    font-weight: 300;
    color: #777;
}

.benefit-item:after {
    content: " ";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, .015);
    opacity: 0;

    -webkit-transition: all 0.37s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);

    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -o-transform: scale(0.5);
    -ms-transform: scale(0.5);
    transform: scale(0.5);
}

.benefit-item:hover:after {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    -o-transform: none;
    -ms-transform: none;
    transform: none;
}

.benefit-item:hover .benefit-number {
    color: rgba(0, 0, 0, .035);
    -webkit-transform: scale(0.75);
    -moz-transform: scale(0.75);
    -o-transform: scale(0.75);
    -ms-transform: scale(0.75);
    transform: scale(0.75);
}



/* ==============================
   Counters
   ============================== */

.count-wrapper {
    padding: 40px 20px;
    border: 1px solid rgba(0, 0, 0, .09);
}

.count-number {
    font-size: 48px;
    font-weight: 300;
    line-height: 1.3;
    text-align: center;
}

.count-descr {
    color: #999;
    font-size: 11px;
    text-align: center;
}

.count-descr .fa {
    font-size: 14px;
}

.count-title {
    margin-top: -1px;
    font-size: 13px;
}

.count-separator {
    height: 30px;
    clear: both;
    float: none;
    overflow: hidden;
}



/* ==============================
   Portfolio
   ============================== */


/*
 * Works filter
 */

.works-filter {
    list-style: none;
    padding: 0;
    margin: 0 0 45px 0;
    font-size: 18px;
    font-weight: 300;
    text-align: center;
    color: #555;
    cursor: default;
}

.works-filter a {
    display: inline-block;
    margin: 0 18px;
    color: #888;
    text-decoration: none;

    -webkit-transition: all 0.37s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);

    cursor: pointer;
}

.works-filter a:hover {
    color: #111;
    text-decoration: none;
}

.works-filter a.active {
    color: #111;
    cursor: default;
}

.works-filter a.active:hover {
    color: #111;
}


/*
 * Works grid 
 */

.works-grid {
    list-style: none;
    margin: 0;
    padding: 0;
}

.work-item {
    width: 25%;
    float: left;
    margin: 0;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.work-grid-2 .work-item {
    width: 50%;
}

.work-grid-3 .work-item {
    width: 33.333%;
}

.container .work-grid-3 .work-item {
    width: 33.2%;
}

.work-grid-5 .work-item {
    width: 20%;
}

.works-grid.work-grid-gut {
    margin: 0 0 0 -10px;
}

.works-grid.work-grid-gut .work-item {
    padding: 0 0 10px 10px;
}

.work-item>a {
    display: block;
    padding: 0 0 84px 0;
    position: relative;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    text-decoration: none;
}

.work-item a,
.work-item a:hover {
    color: #111;
    border: none;
}

.work-img {
    position: relative;
    overflow: hidden;
}

.work-img img {
    width: 100%;
    display: block;
    overflow: hidden;
}

.work-intro {
    width: 100%;
    height: 84px;
    padding: 20px 20px 0 20px;

    -webkit-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    position: absolute;
    bottom: 0;
    left: 0;
    text-align: center;
    overflow: hidden;
}

.work-title {
    margin: 0 0 8px 0;
    padding: 0;
    font-size: 13px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 1.2;
}

.work-descr {
    font-size: 11px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #aaa;
    line-height: 1.2;
}

.works-grid.hide-titles .work-item>a {
    overflow: hidden;
    padding: 0;
}

.works-grid.hide-titles .work-item .work-intro {
    opacity: 0;
    bottom: -94px;
}

.works-grid.hide-titles .work-item:hover .work-intro {
    opacity: 1;
}



/* Work item hover state */

.work-intro {
    -webkit-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
}

.work-item:hover .work-intro {
    /*bottom: auto;*/
    /*top: 50%;*/
    color: #fff;

    -webkit-transform: translateY(-99px);
    -moz-transform: translateY(-99px);
    transform: translateY(-99px);

    z-index: 3;
}

.work-item:hover .work-title {
    color: #ccc;
}

.work-item:hover .work-descr {
    color: #999;
}

.work-item .work-img:after {
    content: " ";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(250, 250, 250, 0);

    -webkit-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);

}

.work-item:hover .work-img:after {
    background: rgba(20, 20, 20, .85);
}

.works-grid.hover-color .work-item:hover .work-intro,
.works-grid.hover-color .work-item:hover .work-title,
.works-grid.hover-color .work-item:hover .work-descr {
    color: #fff;
}

.works-grid.hover-color .work-item:hover .work-img:after {
    background: #e91e63;
    opacity: .73;
}

.works-grid.hover-white .work-item:hover .work-intro,
.works-grid.hover-white .work-item:hover .work-title,
.works-grid.hover-white .work-item:hover .work-descr {
    color: #000;
}

.works-grid.hover-white .work-item:hover .work-img:after {
    background: rgba(250, 250, 250, .9);
}

.work-item .work-img>img {
    -webkit-transition: all 0.3s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -moz-transition: all 0.3s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -o-transition: all 0.3s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -ms-transition: all 0.3s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    transition: all 0.3s cubic-bezier(0.300, 0.100, 0.580, 1.000);
}

.work-item:hover .work-img>img {
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -o-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
}

/*
 * Works full view
 */

.work-full {
    display: none;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 10000;
}

.work-full-load {
    padding-top: 60px;
}

.work-loader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 10001;
}

.work-loader:after {
    display: block;
    content: " ";
    width: 16px;
    height: 16px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -8px 0 0 -8px;
    /* background: url(../images/loader.gif) no-repeat; */
}

/* Work navigation */

.work-navigation {
    width: 100%;
    background: #fff;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 2px;

    z-index: 99;
}

.work-navigation .fa {
    font-size: 12px;
    vertical-align: middle;
}

.work-navigation .fa-times {
    font-size: 16px;
    margin-top: -2px;
}

.work-navigation a {
    display: block;
    float: left;
    width: 33.33333%;
    overflow: hidden;
    vertical-align: middle;
    text-decoration: none;
    white-space: nowrap;
    cursor: default;
}

.work-navigation a,
.work-navigation a:visited {
    color: #555;
}

.work-navigation a:hover {
    color: #111;
}

.work-navigation a span {
    display: inline-block;
    height: 60px;
    line-height: 59px;
    padding: 0 4%;
    background: #fff;
    cursor: pointer;

    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.work-navigation span:hover {
    background: #f7f7f7;
}

.work-back-link {
    margin: 20px 0 0;
    display: block;
    background: #fff;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #151515;
}

.work-prev {
    text-align: left;
}

.work-all {
    text-align: center;
}

.work-next {
    text-align: right;
}

.work-container {
    padding: 50px 0;
}

.work-full-title {
    margin: 0 0 10px 0;
    padding: 0;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1px;
}

.work-full-media {
    margin-top: 30px;
}

.work-full-media img {
    max-width: 100%;
    height: auto;
}

.work-full-text {
    text-align: left;
    font-size: 13px;
    color: #777;
}

.work-full-text h6 {
    margin-top: 5px;
}

.work-detail {
    font-weight: 400;
}

.work-detail p:last-child {
    margin-bottom: 0;
}

.work-full-detail {
    font-size: 11px;
    color: #777;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.work-full-detail p {
    padding: 4px 0;
    margin: 0;
    border-top: 1px solid rgba(0, 0, 0, .05);
}

.work-full-detail p:last-child {
    border-bottom: 1px solid rgba(0, 0, 0, .05);
}

.work-full-detail p strong {
    width: 100px;
    display: inline-block;
}

.work-full-action {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid #eaeaea;
    text-align: center;
}



/* ==============================
   Banner section
   ============================== */

.banner-section {
    overflow: visible;
}

.banner-heading {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.9);
}

.banner-decription {
    margin-bottom: 35px;
    font-size: 16px;
    font-weight: 300;
    color: rgba(255, 255, 255, .7);
}

.banner-image {
    margin: -40px 0 0 0;
    text-align: right;
}

.banner-image img {}


/* ==============================
   Testimonial
   ============================== */

.testimonial {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 0;
    opacity: .9;
}

.testimonial p {
    font-size: 18px;
    line-height: 1.7778;
}

.testimonial-author {
    font-size: 11px;
    opacity: .75;
}

/*
 * Logotypes
 */

.logo-item {
    height: 80px;
    text-align: center;
    opacity: .75;

    -webkit-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);

}

.logo-item:hover {
    opacity: 1;
}

.logo-item img {
    position: relative;
    top: 50%;

    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}



/* ==============================
   Post preview
   ============================== */

.post-grid {}

.post-prev-img {
    margin-bottom: 15px;
    position: relative;
}

.post-prev-img a {
    -webkit-transition: all 0.2s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -moz-transition: all 0.2s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -o-transition: all 0.2s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -ms-transition: all 0.2s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    transition: all 0.2s cubic-bezier(0.300, 0.100, 0.580, 1.000);
}

.post-prev-img a:hover {
    opacity: .85;
}

.post-prev-img img {
    width: 100%;
}

.post-prev-border {
    border-bottom: 2px solid #e91e63;
}

.post-prev-title {
    margin-bottom: 1px;
    font-size: 20px;
    font-weight: 300;
}

.post-prev-title a {
    color: #555;
    text-decoration: none;

    -webkit-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
}

.post-prev-title a:hover {
    color: #999;
}

.demos-grid .post-prev-title {
    font-size: 16px;
}

.post-prev-info,
.post-prev-info a {
    margin-bottom: 10px;
    font-size: 13px;
    text-decoration: none;
    color: #aaa;

    -webkit-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
}

.post-prev-info a:hover {
    color: #111;
}

.post-prev-text {
    margin-bottom: 22px;
    color: #111;
    font-size: 15px;
    font-weight: 300;
    line-height: 1.75;
}



/* ==============================
   Newsletter
   ============================== */

.newsletter-label {
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.3em;
}

.form input.newsletter-field {
    max-width: 370px;
}

.form input.newsletter-field:hover {}

.form input.newsletter-field:focus {}

.form input.newsletter-field::-webkit-input-placeholder {
    color: #777;
}

.form input.newsletter-field:focus::-webkit-input-placeholder {
    color: #111;
}

.form input.newsletter-field:-moz-placeholder {
    color: #777;
}

.form input.newsletter-field:focus:-moz-placeholder {
    color: #111;
}

.form input.newsletter-field::-moz-placeholder {
    color: #777;
}

.form input.newsletter-field:focus::-moz-placeholder {
    color: #111;
}

.form input.newsletter-field:-ms-input-placeholder {
    color: #777;
}

.form input.newsletter-field:focus:-ms-input-placeholder {
    color: #111;
}


/* ==============================
   Contact section
   ============================== */

.email-link a {
    color: #777;
    text-decoration: none;
}

.email-link a:hover {
    color: #111;
    text-decoration: none;
}


/*
 * Contact form
 */

.cf-left-col {
    width: 49%;
    float: left;
    margin-right: 2%;
}

.cf-right-col {
    width: 49%;
    float: left;
    ;
}

.contact-form .form-group {
    margin-bottom: 20px;
}



/* ==============================
   Google maps 
   ============================== */

.google-map {
    position: relative;
    background: #111;
}

.google-map>iframe {
    display: block;
    width: 100%;
    height: 410px;
    position: relative;
    background-color: #111 !important;
    z-index: 1;
}

.gm-style-cc {
    display: none !important;
}

.gm-style-iw div {
    color: #777;
}

.map-section {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 !important;
    background: rgba(17, 17, 17, .93);
    z-index: 2;
    cursor: pointer;

    -webkit-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
}

.map-section:hover {
    background: rgba(17, 17, 17, .90);
}

.map-toggle {
    width: 200px;
    height: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -50px 0 0 -100px;
    color: #fff;
    text-align: center;
}

.mt-icon {
    width: 40px;
    height: 40px;
    line-height: 40px;
    margin: 6px auto 25px;
    position: relative;
    top: 4px;
    color: #111;
}

.mt-icon:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;

}

.mt-icon i,
.mt-icon span {
    position: relative;
}

.mt-text {
    margin-left: 12px;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: rgba(255, 255, 255, .75);
}

.mt-close {
    display: none;
}

/* Map opened */

.map-section.js-active {
    height: 25px;
    background: rgba(17, 17, 17, .75);
}

.map-section.js-active .mt-text {
    font-size: 12px;
}

.map-section.js-active:hover {
    background: rgba(17, 17, 17, .85);
}

.map-section.js-active .mt-icon {
    opacity: 0;
}

.map-section.js-active .mt-text {
    position: relative;
    top: -30px;
}


/* ==============================
   Footer
   ============================== */

.footer {
    text-align: center;
    font-size: 12px;
    color: #999;
}

.footer a {
    color: #ddd;
    text-decoration: none;

    -webkit-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
}

.footer a:hover {
    color: #fff;
}

.footer-copy {
    margin-bottom: 5px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.footer-made {
    font-size: 13px;
}


.footer-social-links {
    font-size: 14px;
}

.footer-social-links a {
    width: 38px;
    height: 38px;
    line-height: 39px !important;
    position: relative;
    margin: 0 2px;
    text-align: center;
    display: inline-block;
    color: #111;

    -webkit-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
}

.footer-social-links a i,
.footer-social-links a span[class^=icon] {
    position: relative;
    top: 0;
    left: 0;
}

.footer-social-links a:before {
    content: "";
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;


    -webkit-transition: all 0.2s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -moz-transition: all 0.2s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -o-transition: all 0.2s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -ms-transition: all 0.2s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    transition: all 0.2s cubic-bezier(0.300, 0.100, 0.580, 1.000);
}

.footer-social-links a:hover {
    color: #fff;
}

.footer-social-links a:hover:before {
    background: #111;
}

.footer-social-links a:after {
    content: "";
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 2px solid #fff;
    opacity: 0;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;

    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -o-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);

    -webkit-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
}

.footer-social-links a:hover:after {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

a.link-to-top {
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: rgba(255, 255, 255, .2);
    font-size: 48px;
}


/* ==============================
   Blog
   ============================== */

.blog-item {
    margin-bottom: 80px;
    position: relative;
}

.blog-item-date {
    width: 100px;
    padding: 10px;
    position: absolute;
    top: 0px;
    left: -130px;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: center;
    color: #999;
    border-right: 1px solid #eee;

}

.date-num {
    display: block;
    margin-bottom: 3px;
    font-size: 18px;
    font-weight: 400;
    color: #555;
}

.blog-media {
    margin-bottom: 30px;
}

.blog-media img,
.blog-media .video,
.blog-media iframe {}

.blog-item-title {
    margin: 0 0 .5em 0;
    padding: 0;
    font-size: 24px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;

}

.blog-item-title a {
    color: #111;
    text-decoration: none;
}

.blog-item-title a:hover {
    color: #777;
    text-decoration: none;
}

.blog-item-data {
    margin-bottom: 30px;
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 1px;
    color: #aaa;
}

.separator {
    margin: 0 5px;
}

.blog-item-data a {
    color: #aaa;
    text-decoration: none;
    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.blog-item-data a:hover {
    color: #000;
    text-decoration: none;
}

.blog-post-data {
    margin-top: 10px;
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 1px;
    color: #aaa;
}

.blog-item-body {
    font-size: 17px;
    font-weight: 300;
    color: #555;
    line-height: 1.8;
}

.blog-item-body h1,
.blog-item-body h2,
.blog-item-body h3,
.blog-item-body h4,
.blog-item-body h5,
.blog-item-body h6 {
    margin: 1.3em 0 0.5em 0;
}

.blog-item-more {
    border: 1px solid #eaeaea;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    color: #777;
    letter-spacing: 1px;

    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.blog-item-more:hover {
    text-decoration: none;
    border-color: #111;
}

a.blog-item-more {
    color: #777;
    text-decoration: none;
}

a.blog-item-more:hover {
    color: #111;
}

.blog-item-q {
    background: none;
    border: none;
    padding: 0;
    margin: 1em 0;
}

.blog-item-q p {
    position: relative;
    background: #f8f8f8;
    padding: 17px 20px;
    font-size: 20px;
    font-weight: 300;
    font-style: normal;
    letter-spacing: 0;
}

.blog-item-q p a {
    color: #555;
    text-decoration: none;
    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.blog-item-q:hover a,
.blog-item-q p a:hover {
    text-decoration: none;
    color: #777;
}

.blog-item-q p:before,
.blog-item-q p:after {
    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.blog-item-q p:before {
    content: '"';
    position: absolute;
    top: 10px;
    left: 10px;
}

.blog-item-q p:after {
    content: '"';
}

.blog-item-q p:hover:before,
.blog-item-q p:hover:after {
    color: #777;
}

.blog-page-title {
    margin-bottom: 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
}


/*
 * Pagination
 */

.pagination {
    font-size: 13px;
    font-family: "Open Sans", arial, sans-serif;
}

.pagination a {
    display: inline-block;
    min-width: 30px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    padding: 7px 14px;
    border: 1px solid #eaeaea;
    color: #999;
    font-size: 13px;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;

    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.pagination .fa {
    margin-top: 1px;
    font-size: 16px;
}

.pagination a:hover {
    text-decoration: none;
    border-color: #bbb;
    color: #444;
}

.pagination a.active,
.pagination a.active:hover {
    border-color: #bbb;
    color: #444;
    cursor: default;
}

.pagination a.no-active,
.pagination a.no-active:hover {
    border-color: #efefef;
    color: #bbb;
    cursor: default;
}



/*
 * Comments
 */
.comment-list {}

.comment-item {
    padding-top: 30px !important;
    border-top: 1px dotted #e9e9e9;
    font-size: 14px;
    overflow: hidden;
}

.comment-item:first-child {
    padding-top: 0;
    border-top: none;
}

.media-body {
    overflow: hidden;
}

.comment-avatar {
    width: 50px;
    margin-right: 10px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.comment-item-data {
    margin: 0 0 5px;
    text-transform: uppercase;
    font-family: "Open Sans", Arial, Helvetica, sans-serif;
    font-size: 12px;
    color: #777;
}

.comment-item-data a {
    color: #777;
    text-decoration: none;
    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.comment-item-data a:hover {
    color: #000;
    text-decoration: none;
}

.comment-author {
    display: inline-block;
    margin-right: 20px;
    font-size: 11px;
    font-weight: 700;
}

.comment-author a {
    color: #999;
}




/*
 * Sidebar
 */

.sidebar {}

.widget {
    margin-bottom: 60px;
}

.widget .img-left {
    margin: 0 10px 10px 0;
}

.widget .img-right {
    margin: 0 0 10px 10px;
}

.widget-title {
    margin-bottom: 20px;
    padding-bottom: 5px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
    color: #777;
    border-bottom: 1px solid #ccc;
}

.widget-body {
    font-size: 14px;
    color: #777;
}

.widget-text {
    line-height: 1.7;
}


/* Search widget */

.search-wrap {
    position: relative;
}

.search-field {
    width: 100% !important;
    height: 50px !important;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 13px !important;
}

.search-button {
    width: 42px;
    height: 48px;
    line-height: 38px;
    margin-top: -24px;
    position: absolute;
    top: 50%;
    right: 1px;
    overflow: hidden;
    background: #fff;
    border: none;
    outline: none;
    color: #999;
    font-size: 14px;
}

.search-button:hover {
    color: #111;
}

.search-button:hover+.search-field {
    border-color: #ccc;
}


/* Widget menu */

.widget-menu {
    font-size: 15px;
}

.widget-menu li {
    padding: 5px 0;

}

.widget-menu li a {
    color: #555;
    text-decoration: none;
    padding-bottom: 2px;
    text-decoration: none;
    border-bottom: 1px solid transparent;

    -webkit-transition: all 0.17s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: all 0.17s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: all 0.17s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.17s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.17s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.widget-menu li a:hover,
.widget-menu li a.active {
    color: #999;
}

.widget-menu li small {
    font-size: 11px;
    color: #aaa;
}


/* Widget tags */

.tags {}

.tags a {
    display: inline-block;
    margin: 0 2px 5px 0;
    padding: 5px 7px;
    border: 1px solid #e9e9e9;
    color: #777;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 1px;

    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.tags a:hover {
    text-decoration: none;
    border-color: #333;
    color: #111;
}

/* Comments */
.widget-comments {
    color: #aaa;
}

.widget-comments li {
    padding: 12px 0;
    border-bottom: 1px dotted #eaeaea;
}

.widget-comments li a {
    color: #555;
    text-decoration: none;

    -webkit-transition: all 0.17s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: all 0.17s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: all 0.17s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.17s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.17s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.widget-comments li a:hover {
    color: #999;
}


/* Posts */
.widget-posts {
    color: #aaa;
}

.widget-posts li {
    font-size: 12px;
    padding: 12px 0;
    border-bottom: 1px dotted #eaeaea;
}

.widget-posts li a {
    font-size: 13px;
    display: block;
    color: #555;
    text-decoration: none;

    -webkit-transition: all 0.17s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: all 0.17s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: all 0.17s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.17s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.17s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.widget-posts li a:hover {
    color: #999;
}

.widget-posts-img {
    float: left;
    margin: 0 7px 0 0;
}

.widget-posts-descr {
    overflow: hidden;
}


/* ==============================
   Shop
   ============================== */

.shopping-cart-table {}

.shopping-cart-table a {
    color: #111;
    text-decoration: none;
}

.shopping-cart-table a:hover {
    color: #777;
    text-decoration: none;
}

table.shopping-cart-table tr th,
table.shopping-cart-table tr td {
    vertical-align: middle;
}


/* ==============================
   Pricing
   ============================== */

.pricing-item {
    margin: 0 0 30px;
    position: relative;
    text-align: center;
}

.pricing-item-inner {
    border: 1px solid #eaeaea;
    vertical-align: middle;

    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.pricing-item-inner:hover {
    border-color: #aaa;
}

.main .pricing-item-inner {
    border-color: #aaa;
}

.pricing-wrap {
    position: relative;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    padding: 30px 20px;
    text-align: center;
    overflow: hidden;
}

.pricing-icon {
    padding-top: 10px;
    padding-bottom: 5px;
    position: relative;
    font-size: 32px;
    z-index: 1;
}

.pricing-title {
    position: relative;
    margin-bottom: 40px;
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    z-index: 1;
}

.pr-list {
    margin: 0 -20px 30px;
    padding: 0;

    color: #777;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.pr-list li {
    padding: 12px 20px;
    list-style: none;
    border-bottom: 1px solid #f1f1f1;
}

.pr-list li:first-child {
    border-top: 1px solid #f1f1f1;
}

.pricing-num {
    font-size: 42px;
    font-weight: 300;
    line-height: 1;

}

.pricing-num sup {
    font-family: "Open Sans", Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: 400;
    position: relative;
    top: -20px;
    left: -3px;
    margin-left: -7px;
}

.pr-button {
    margin: 30px -20px 0;
    padding-top: 30px;
    border-top: 1px solid #f1f1f1;
}

.pr-per {
    color: #999;
    font-size: 12px;
}



/* ==============================
   Shortcodes
   ============================== */

.margin-block {
    margin-bottom: 30px;
}


/*
 * Owl Carousel
 */

.owl-buttons {
    position: static;
}

.owl-prev,
.owl-next {
    opacity: 0;
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -30px;
    width: 40px;
    height: 60px;
    line-height: 60px;
    font-size: 74px;
    text-align: center;
    color: rgba(255, 255, 255, .8);

    text-shadow: 1px 1px 0px rgba(0, 0, 0, .1);

    z-index: 6;

    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.owl-prev {
    left: 0;
}

.owl-next {
    right: 0;
}

.owl-prev:hover,
.owl-next:hover {
    color: rgba(255, 255, 255, .95);

    -webkit-transition: 0 none !important;
    -moz-transition: 0 none !important;
    -ms-transition: 0 none !important;
    -o-transition: 0 none !important;
    transition: 0 none !important;
}

.owl-prev:active,
.owl-next:active {
    -webkit-transform: scale(0.94);
    -moz-transform: scale(0.94);
    -o-transform: scale(0.94);
    -ms-transform: scale(0.94);
    transform: scale(0.94);
}

.owl-carousel:hover .owl-prev {
    opacity: 1;
    left: 25px;
}

.owl-carousel:hover .owl-next {
    opacity: 1;
    right: 25px;
}

.owl-carousel .owl-prev:focus {
    opacity: 1;
    left: 25px;
}

.owl-carousel .owl-next:focus {
    opacity: 1;
    right: 25px;
}

.owl-pagination {
    display: block;
    width: 100%;
    position: absolute;
    bottom: 45px;
    left: 0;
    text-align: center;
    z-index: 100 !important;
}

.owl-page {
    display: inline-block;
    padding: 6px 4px;
}

.owl-page span {
    width: 13px;
    height: 13px;
    display: block;
    background: transparent;
    border: 2px solid rgba(255, 255, 255, .8);
    position: relative;
    z-index: 1000 !important;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;

    -webkit-box-shadow: 1px 1px 0px rgba(0, 0, 0, .1);
    -moz-box-shadow: 1px 1px 0px rgba(0, 0, 0, .1);
    box-shadow: 1px 1px 0px rgba(0, 0, 0, .1);

}

.owl-page:hover span {
    background: rgba(255, 255, 255, .8);
    border: none;
}

.owl-page.active span {
    background: rgba(255, 255, 255, .95);
    border: none;
}

.item-carousel .owl-prev {
    left: -125px;
}

.item-carousel .owl-next {
    right: -125px;
}

.item-carousel.owl-carousel:hover .owl-prev,
.item-carousel.owl-carousel .owl-prev:focus,
.small-item-carousel.owl-carousel:hover .owl-prev,
.small-item-carousel.owl-carousel .owl-prev:focus {
    opacity: 0;
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
}

.item-carousel.owl-carousel:hover .owl-next,
.item-carousel.owl-carousel .owl-next:focus,
.small-item-carousel.owl-carousel:hover .owl-next,
.small-item-carousel.owl-carousel .owl-next:focus {
    opacity: 0;
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
}

.item-carousel .owl-pagination {
    bottom: -95px;
}

.work-full-slider:hover .owl-prev,
.content-slider:hover .owl-prev {
    left: 10px;
}

.work-full-slider:hover .owl-next,
.content-slider:hover .owl-next {
    right: 10px;
}

.work-full-slider .owl-pagination,
.content-slider .owl-pagination {
    bottom: 15px;
}

.single-carousel,
.small-item-carousel {
    margin-bottom: 35px;
}

.single-carousel .owl-pagination,
.small-item-carousel .owl-pagination {
    position: relative;
    left: 0;
    top: 35px;
    margin: 0;
}

.owl-carousel.dark-slider .owl-page span {
    border-color: rgba(0, 0, 0, .4);
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.owl-carousel.dark-slider .owl-page:hover span {
    background-color: rgba(0, 0, 0, .4);
}

.owl-carousel.dark-slider .owl-page.active span {
    background-color: rgba(0, 0, 0, .5);
}

.owl-carousel.dark-slider .owl-prev,
.owl-carousel.dark-slider .owl-next {
    color: rgba(0, 0, 0, .5);
    text-shadow: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.owl-carousel.dark-slider .owl-prev:hover,
.owl-carousel.dark-slider .owl-next:hover {
    color: rgba(0, 0, 0, .65);
}

.single-carousel .owl-prev,
.small-item-carousel .owl-prev {
    left: -125px;
}

.single-carousel .owl-next,
.small-item-carousel .owl-next {
    right: -125px;
}

section:hover .single-carousel .owl-prev,
section:hover .small-item-carousel .owl-prev {
    opacity: 1;
    left: -80px;
}

section:hover .single-carousel .owl-next,
section:hover .small-item-carousel .owl-next {
    opacity: 1;
    right: -80px;
}

.photo-slider-item {}



/*
 * Tabs
 */

.tpl-tabs {
    border-color: #e5e5e5;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.tpl-tabs>li>a {
    text-decoration: none;
    color: #777;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.tpl-tabs>li>a:hover {
    background-color: #f5f5f5;
}

.tpl-tabs li.active a {
    color: #000;
}

.tpl-tabs-cont {
    padding: 30px 0;
    color: #777;
    line-height: 1.8;
}

/*
 * Tabs minimal
 */

.tpl-minimal-tabs {
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1px;
    border: none;
}

.tpl-minimal-tabs>li {
    padding: 0 30px;
    background: none !important;
}

.tpl-minimal-tabs>li>a {
    padding: 6px 20px 5px;
    text-decoration: none;
    color: #000;
    background: none;
    border: 1px solid transparent;

    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
}

.tpl-minimal-tabs>li>a:hover {
    background: none;
    border-color: #e9e9e9;
    color: #777;
}

.tpl-minimal-tabs li.active a,
.tpl-minimal-tabs li.active a:hover {
    border: 1px solid #222 !important;
    background: none !important;
    color: #000;
    cursor: default;
}

.tpl-minimal-tabs-cont {}


/*
 * Tabs Alternative
 */

.tpl-alt-tabs {
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.4em;
    border: none;
}

.tpl-alt-tabs>li {
    width: 215px;
    display: inline-block;
    vertical-align: top;
    float: none;
    padding: 0;
    background: none !important;

}

.tpl-alt-tabs>li>a {
    text-decoration: none;
    color: #a3a3a3;
    background: none !important;
    border: none !important;

    -webkit-transition: all 500ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
    -moz-transition: all 500ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
    -o-transition: all 500ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
    transition: all 500ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
}

.tpl-alt-tabs>li>a:hover {
    background: none;
    color: #111;
}

.tpl-alt-tabs li.active a,
.tpl-alt-tabs li.active a:hover {
    cursor: default;
    color: #111;
}

.alt-tabs-icon {
    display: block;
    margin-bottom: 12px;
    font-size: 48px;
    letter-spacing: 0;
}

.tpl-minimal-tabs-cont {}


/*
 * Alert messages
 */

.alert {
    margin: 0 auto 10px;
    padding: 14px 20px;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    border: 1px solid #ddd;
    font-size: 11px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #777;

    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;

}

.success {
    border-color: #a9e2c2;
    color: #36a367;
}

.info {
    border-color: #9ecdfc;
    color: #297ad3;
}

.notice {
    border-color: #f9ce75;
    color: #e9a00a;
}

.error {
    border-color: #ffbbbb;
    color: #e33131;
}


/*
 * Accordion
 */

.accordion>dt {
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.accordion>dt>a {
    display: block;
    position: relative;
    color: #777;
    text-decoration: none;
    padding: 14px 20px;
    border: 1px solid #e5e5e5;


    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.accordion>dt>a:hover {
    text-decoration: none;
    border-color: #ddd;
    background-color: #fcfcfc;
    color: #444;
}

.accordion>dt>a.active {
    color: #000;
    border-color: #ddd;
    background-color: #fcfcfc;
    cursor: default;
}

.accordion>dt>a:after {
    content: "\f107";
    width: 15px;
    height: 15px;
    margin-top: -8px;
    position: absolute;
    top: 50%;
    right: 10px;
    font-family: 'FontAwesome';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    font-size: 15px;
    line-height: 15px;
    text-align: center;
    color: #999;
    -webkit-font-smoothing: antialiased;
    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.accordion>dt>a.active:after,
.accordion>dt>a.active:hover:after {
    content: "\f106";
    color: #111;
}

.accordion>dt>a:hover:after {
    color: #444;
}

.accordion>dd {
    margin-bottom: 10px;
    padding: 10px 20px 20px;
    font-size: 15px;
    line-height: 1.8;
    color: #777;
}


/*
 * Toggle
 */

.toggle>dt {
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.2em;
}

.toggle>dt>a {
    display: block;
    position: relative;
    color: #777;
    text-decoration: none;
    padding: 14px 20px;
    border: 1px solid #e5e5e5;

    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.toggle>dt>a:hover {
    text-decoration: none;
    border-color: #ddd;
    background-color: #fcfcfc;
    color: #444;
}

.toggle>dt>a.active {
    color: #000;
    border-color: #ddd;
    background-color: #fcfcfc;
}

.toggle>dt>a:after {
    content: "\f107";
    width: 15px;
    height: 15px;
    margin-top: -8px;
    position: absolute;
    top: 50%;
    right: 10px;
    font-family: 'FontAwesome';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    font-size: 15px;
    line-height: 15px;
    text-align: center;
    color: #999;
    -webkit-font-smoothing: antialiased;
    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.toggle>dt>a.active:after,
.toggle>dt>a.active:hover:after {
    content: "\f106";
    color: #111;
}

.toggle>dt>a:hover:after {
    color: #444;
}

.toggle>dd {
    margin-bottom: 10px;
    padding: 10px 20px 20px;
    font-size: 15px;
    line-height: 1.8;
    color: #777;
}


/*
 * Progress bars
 */

.tpl-progress {
    margin-top: 10px;
    margin-bottom: 50px;
    font-weight: 400;
    overflow: visible;
    height: 2px;
    background: #f0f0f0;

    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.tpl-progress .progress-bar {
    padding-top: 2px;
    position: relative;
    overflow: visible;
    background-color: #222;
    font-size: 14px;
    color: #000;
    text-align: left;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.tpl-progress .progress-bar>span {
    display: inline-block;
    min-width: 24px;
    height: 24px;
    padding: 0 3px;
    position: absolute;
    top: 0px;
    right: -5px;

    text-align: center;
    line-height: 23px;
    letter-spacing: 0;

    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;

}

.tpl-progress.progress-color .progress-bar {
    background-color: #e91e63;
    color: #777;
}

.tpl-progress.progress-color .progress-bar>span {
    background: transparent;
    color: #777;
}

/* Alt bar */
.tpl-progress-alt {
    height: 23px;
    background: #ececec;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.tpl-progress-alt .progress-bar {
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    overflow: visible;
    background-color: #222;
    font-size: 13px;
    font-weight: 400;
    line-height: 23px;
    color: #fff;
    text-align: left;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.tpl-progress-alt.progress-color .progress-bar {
    background-color: #e91e63;
    color: #fff;
}

.tpl-progress-alt.progress-color .progress-bar>span {}

.highlight pre {
    border-color: #eaeaea;
    background: #fcfcfc;
    color: #555;

    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

/*
 * Font icons examples
 */

.fa-examples {
    color: #555;
    margin-left: 1px;
}

.fa-examples>div {
    padding: 0;
    border: 1px solid #e5e5e5;
    margin: -1px 0 0 -1px;
    font-size: 13px;
}

.fa-examples>div:hover {
    background: #f0f0f0;
    color: #000;
}

.fa-examples>div>i {
    display: inline-block;
    margin-right: 5px;
    min-width: 40px;
    min-height: 40px;
    border-right: 1px solid #f1f1f1;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
}

.fa-examples>div>.muted {
    margin-left: 5px;
    color: #999;
    font-size: 12px;
}

.fa-sm {
    width: 20px;
    font-size: 14px;
}

.et-examples {
    color: #555;
    margin-left: 1px;
    vertical-align: middle;
}

.et-examples .box1 {
    display: block;
    width: 33%;
    float: left;
    padding: 0;
    border: 1px solid #e5e5e5;
    margin: -1px 0 0 -1px;
    font-size: 13px;
}

@media only screen and (max-width: 1024px) {
    .et-examples .box1 {
        width: 50%;
    }
}

@media only screen and (max-width: 480px) {
    .et-examples .box1 {
        width: 100%;
    }
}

.et-examples .box1:hover {
    background: #f8f8f8;
    color: #111;
}

.et-examples .box1>span {
    display: inline-block;
    margin-right: 5px;
    min-width: 70px;
    min-height: 70px;
    border-right: 1px solid #f1f1f1;
    line-height: 70px;
    text-align: center;
    font-size: 32px;
}

/*
 *  Intro page
 */

.intro-image {
    width: 100%;
}

.intro-label {
    position: absolute;
    top: 7px;
    right: 10px;
    font-size: 17px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
}


/* ==============================
   Bootstrap multicolumn grid
   ============================== */

/* clear the first in row for any block that has the class "multi-columns-row" */
.multi-columns-row .col-xs-6:nth-child(2n + 3) {
    clear: left;
}

.multi-columns-row .col-xs-4:nth-child(3n + 4) {
    clear: left;
}

.multi-columns-row .col-xs-3:nth-child(4n + 5) {
    clear: left;
}

.multi-columns-row .col-xs-2:nth-child(6n + 7) {
    clear: left;
}

.multi-columns-row .col-xs-1:nth-child(12n + 13) {
    clear: left;
}

@media (min-width: 768px) {

    /* reset previous grid */
    .multi-columns-row .col-xs-6:nth-child(2n + 3) {
        clear: none;
    }

    .multi-columns-row .col-xs-4:nth-child(3n + 4) {
        clear: none;
    }

    .multi-columns-row .col-xs-3:nth-child(4n + 5) {
        clear: none;
    }

    .multi-columns-row .col-xs-2:nth-child(6n + 7) {
        clear: none;
    }

    .multi-columns-row .col-xs-1:nth-child(12n + 13) {
        clear: none;
    }

    /* clear first in row for small columns */
    .multi-columns-row .col-sm-6:nth-child(2n + 3) {
        clear: left;
    }

    .multi-columns-row .col-sm-4:nth-child(3n + 4) {
        clear: left;
    }

    .multi-columns-row .col-sm-3:nth-child(4n + 5) {
        clear: left;
    }

    .multi-columns-row .col-sm-2:nth-child(6n + 7) {
        clear: left;
    }

    .multi-columns-row .col-sm-1:nth-child(12n + 13) {
        clear: left;
    }
}

@media (min-width: 992px) {

    /* reset previous grid */
    .multi-columns-row .col-sm-6:nth-child(2n + 3) {
        clear: none;
    }

    .multi-columns-row .col-sm-4:nth-child(3n + 4) {
        clear: none;
    }

    .multi-columns-row .col-sm-3:nth-child(4n + 5) {
        clear: none;
    }

    .multi-columns-row .col-sm-2:nth-child(6n + 7) {
        clear: none;
    }

    .multi-columns-row .col-sm-1:nth-child(12n + 13) {
        clear: none;
    }

    /* clear first in row for medium columns */
    .multi-columns-row .col-md-6:nth-child(2n + 3) {
        clear: left;
    }

    .multi-columns-row .col-md-4:nth-child(3n + 4) {
        clear: left;
    }

    .multi-columns-row .col-md-3:nth-child(4n + 5) {
        clear: left;
    }

    .multi-columns-row .col-md-2:nth-child(6n + 7) {
        clear: left;
    }

    .multi-columns-row .col-md-1:nth-child(12n + 13) {
        clear: left;
    }
}

@media (min-width: 1200px) {

    /* reset previous grid */
    .multi-columns-row .col-md-6:nth-child(2n + 3) {
        clear: none;
    }

    .multi-columns-row .col-md-4:nth-child(3n + 4) {
        clear: none;
    }

    .multi-columns-row .col-md-3:nth-child(4n + 5) {
        clear: none;
    }

    .multi-columns-row .col-md-2:nth-child(6n + 7) {
        clear: none;
    }

    .multi-columns-row .col-md-1:nth-child(12n + 13) {
        clear: none;
    }

    /* clear first in row for large columns */
    .multi-columns-row .col-lg-6:nth-child(2n + 3) {
        clear: left;
    }

    .multi-columns-row .col-lg-4:nth-child(3n + 4) {
        clear: left;
    }

    .multi-columns-row .col-lg-3:nth-child(4n + 5) {
        clear: left;
    }

    .multi-columns-row .col-lg-2:nth-child(6n + 7) {
        clear: left;
    }

    .multi-columns-row .col-lg-1:nth-child(12n + 13) {
        clear: left;
    }
}