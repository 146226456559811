* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;    
}

body,
html {
    height: 100%;
}

html {
    scroll-behavior: smooth;
}

html,
body,
div,
span,
img,
ol,
ul,
li,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
option,
legend,
label,
table,
th,
td,
tr,
article,
aside,
caption,
figure,
footer,
header,
hgroup,
mark,
nav,
section,
time,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
}

img {
    max-width: 100%;
}

a:hover,
a:focus,
a:active,
.custom-link {
    text-decoration: none;
    outline: none !important;
    border: 0;
}

input:focus {
    outline: none;
}

input::-moz-focus-inner {
    border: 0;
    display: none;
}

input::-webkit-focus-inner {
    border: 0;
    display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

input:focus,
textarea:focus,
select:focus {
    outline: none;
}

::-webkit-input-placeholder {
    color: #666;
    opacity: 1;
    /* Firefox */
}

::-moz-placeholder {
    color: #666;
    opacity: 1;
    /* Firefox */
}

:-ms-input-placeholder {
    color: #666;
    opacity: 1;
    /* Firefox */
}

::-ms-input-placeholder {
    color: #666;
    opacity: 1;
    /* Firefox */
}

::placeholder {
    color: #666;
    opacity: 1;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #666;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #666;
}

table {
    border-collapse: collapse;
}

html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

ul,
li {
    margin: 0;
    padding: 0;
}

figure {
    margin: 0px;
}

form {
    position: relative;
}

.form-control:focus {
    outline: none !important;
    border: none !important;
    -webkit-box-shadow: 0 0 0;
    box-shadow: 0 0 0;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    font-size: inherit;
    color: inherit;
    font-weight: inherit;
    line-height: inherit;
}

h1:hover a,
h2:hover a,
h3:hover a,
h4:hover a,
h5:hover a,
h6:hover a {
    font-size: inherit;
    color: inherit;
    font-weight: inherit;
}

hr {
    margin: 0;
    padding: 0;
    border-color: #000;
}

ul {
    list-style-type: none;
}

li {
    list-style: none;
    position: relative;
}

a,
button {
    position: relative;
}

a:hover,
a:focus,
button:hover,
button:focus {
    outline: 0;
    text-decoration: none;
}

input,
textarea,
button {
    border: 0px;
}

.container {
    width: 100%;
}

@media (min-width: 1300px) {
    .container {
        max-width: 1200px;
    }
}